import * as ActionTypes from '../constants/actionTypes';

import {
    fetchReducer,
    mutationReducer,
    addOrReplaceIn,
    removeIn
} from './mutators';

const initState = {
    // Required for fetchReducer/mutationReducer
    isFetching: false,
    isProcessing: false,
    errors: {},

    places: [],
    pagination: {}
};

export default function placeReducer(state = initState, action) {
    switch (action.type) {
        case ActionTypes.PLACE_FETCH_INIT:
        case ActionTypes.PLACE_FETCH_SUCCESS:
        case ActionTypes.PLACE_FETCH_ERROR:
            if (action.errors) {
                state.places = [];
            }

            return fetchReducer(state, action, (state) => {
                state.places = action.places;
            });

        case ActionTypes.PLACE_CHANGE_INIT:
        case ActionTypes.PLACE_CHANGE_SUCCESS:
        case ActionTypes.PLACE_CHANGE_ERROR:
            return mutationReducer(state, action, (state) => {
                if (action.places) {
                    return ({
                        places: action.places
                    });
                } else if (action.removedId) {
                    removeIn(state.places, action.removedId);
                } else {
                    addOrReplaceIn(state.places, action.place);
                }
            });

        default:
            return state;
    }
}
