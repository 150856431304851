import * as ActionTypes from '../constants/actionTypes';

// Map
const mapInitState = {
    isLoading: false,
    areLoaded: [],
    currentMapId: undefined,

    mapBounds: [],
    mapCenter: [],

    centeredMarkerType: undefined,
    centeredMarkerId: undefined,
    centeredMarkerParentId: undefined,

    selectedMarkerType: undefined,
    selectedMarkerId: undefined,
    selectedMarkerValue: undefined,

    affiliatedType: undefined,
    affiliatedElements: undefined,

    currentRideId: undefined,
    pois: [],
    currentPOI: undefined,
    currentPOIPictureIndex: undefined,

    visibleRides: [],
    visibleShops: [],
    markerType: undefined,
    markerId: undefined,
    markerUrl: undefined,
    isPopupClosed: true,
    highlightPoiIds: [],

    isChoroplethLoaded: false,
    areaType: undefined,
    areaId: undefined,
    areaName: undefined,
    areaElements: undefined,

    isLoadingCharts: false,

    isGeolocating: false,
    isTracking: false,
    isAnimatingTrack: false,
    locationMode: undefined,
    locationLatitude: undefined,
    locationLongitude: undefined
};

export function mapReducer(state = mapInitState, action) {
    switch (action.type) {
        case ActionTypes.MAP_MAP_LOAD:
            state.currentMapId = action.currentMapId;
            state.isLoading = true;
            return state;
        case ActionTypes.MAP_MAP_LOADED:
            state.currentMapId = action.currentMapId;
            state.areLoaded.push(action.currentMapId);
            state.isLoading = false;
            return state;
        case ActionTypes.MAP_MARKER_CHANGED:
            state.currentMapId = action.currentMapId;
            state.visibleRides = action.visibleElements.filter((element) => element.type === 'ride');
            state.visibleShops = action.visibleElements.filter((element) => element.type === 'shop');
            return state;
        case ActionTypes.MAP_MARKER_CLICKED:
            state.currentMapId = action.currentMapId;
            state.markerType = action.markerType;
            state.markerId = action.markerId;
            return state;
        case ActionTypes.MAP_MARKER_LINK_CLICKED:
            state.currentMapId = action.currentMapId;
            state.markerType = action.markerType;
            state.markerId = action.markerId;
            state.markerUrl = action.markerUrl;
            return state;
        case ActionTypes.MAP_MARKER_POPUP_CLOSED:
            state.currentMapId = action.currentMapId;
            state.isPopupClosed = action.isPopupClosed;
            return state;
        case ActionTypes.MAP_MARKER_CENTER:
            state.currentMapId = action.currentMapId;
            state.centeredMarkerType = action.markerType;
            state.centeredMarkerId = action.markerId;
            state.centeredMarkerParentId = action.markerParentId;
            return state;
        case ActionTypes.MAP_MARKER_SELECT:
            state.currentMapId = action.currentMapId;
            state.selectedMarkerType = action.markerType;
            state.selectedMarkerId = action.markerId;
            state.selectedMarkerValue = action.isSelected;
            return state;
        case ActionTypes.MAP_HIGHLIGHT_POIS:
            state.currentMapId = action.currentMapId;
            state.highlightPoiIds = action.poiIds;
            return state;
        case ActionTypes.MAP_EXPLORE_SEARCHED:
            state.currentMapId = action.currentMapId;
            state.mapBounds = action.mapBounds;
            state.mapCenter = action.mapCenter;
            return state;
        case ActionTypes.MAP_AFFILIATED_CHANGED:
            state.currentMapId = action.currentMapId;
            state.affiliatedType = action.affiliatedType;
            state.affiliatedElements = action.affiliatedElements;
            return state;
        case ActionTypes.MAP_POI_LOADED:
            state.currentMapId = action.currentMapId;
            state.currentRideId = action.rideId;
            state.pois = action.poisData;
            return state;
        case ActionTypes.MAP_POI_PICTURE_CLICKED:
            state.currentMapId = action.currentMapId;
            state.currentPOI = action.poiData;
            state.currentPOIPictureIndex = action.poiPictureIndex;
            return state;
        case ActionTypes.MAP_POI_PICTURE_CHANGE:
            state.currentMapId = action.currentMapId;
            state.currentPOIPictureIndex = action.poiPictureIndex;
            state.currentPOI = action.poiData || (action.poiPictureIndex ? state.currentPOI : undefined);
            return state;
        case ActionTypes.MAP_CHOROPLETH_LOADED:
            state.currentMapId = action.currentMapId;
            state.isChoroplethLoaded = action.isChoroplethLoaded;
            return state;
        case ActionTypes.MAP_CHOROPLETH_DEPARTMENT_CLICKED:
            state.currentMapId = action.currentMapId;
            state.areaType = action.areaType;
            state.areaId = action.areaId;
            state.areaName = action.areaName;
            state.areaElements = action.areaElements;
            return state;
        case ActionTypes.MAP_CHART_LOAD:
            state.currentMapId = action.currentMapId;
            state.isLoadingCharts = true;
            return state;
        case ActionTypes.MAP_LOCATION_GEOLOCATE:
            state.currentMapId = action.currentMapId;
            state.isGeolocating = true;
            return state;
        case ActionTypes.MAP_LOCATION_GEOLOCATED:
            state.currentMapId = action.currentMapId;
            state.isGeolocating = false;
            state.locationMode = action.locationMode;
            state.locationLatitude = action.latitude;
            state.locationLongitude = action.longitude;
            return state;
        case ActionTypes.MAP_LOCATION_TRACK:
            state.currentMapId = action.currentMapId;
            state.isTracking = true;
            return state;
        case ActionTypes.MAP_LOCATION_UNTRACK:
            state.currentMapId = action.currentMapId;
            state.isTracking = false;
            return state;
        case ActionTypes.MAP_ANIMATE_TRACK:
            state.currentMapId = action.currentMapId;
            state.isAnimatingTrack = true;
            return state;
        default:
            return state;
    }
}

// Address - Coordinates
const addressInitState = {
    isFetchingCoordinates: false,
    coordinates: [],

    isFetchingAddress: false,
    addresses: [],

    isFetchingCity: false,
    postcodeRequested: undefined,
    cities: []
};

export function addressReducer(state = addressInitState, action) {
    switch (action.type) {
        case ActionTypes.MAP_COORDINATES_INIT:
            state.isFetchingCoordinates = action.isFetchingCoordinates;
            return state;
        case ActionTypes.MAP_COORDINATES_FOUND:
            state.isFetchingCoordinates = action.isFetchingCoordinates;
            state.coordinates = action.coordinates;
            return state;
        case ActionTypes.MAP_ADDRESS_INIT:
            state.isFetchingAddress = action.isFetchingAddress;
            return state;
        case ActionTypes.MAP_ADDRESS_FOUND:
            state.isFetchingAddress = action.isFetchingAddress;
            state.addresses = action.addresses;
            return state;
        case ActionTypes.MAP_CITY_INIT:
            state.isFetchingCity = action.isFetchingCity;
            return state;
        case ActionTypes.MAP_CITY_FOUND:
            state.isFetchingCity = action.isFetchingCity;
            state.postcodeRequested = action.postcodeRequested;
            state.cities = action.cities;
            return state;
        default:
            return state;
    }
}
