import * as ActionTypes from '../constants/actionTypes';

import {
    fetchReducer,
    mutationReducer,
    addOrReplaceIn,
    removeIn
} from './mutators';

const initState = {
    // Required for fetchReducer/mutationReducer
    isFetching: false,
    isProcessing: false,
    errors: {},

    rides: [],
    pagination: {},

    currentRideType: undefined,
    ride: undefined,
    processingJobId: undefined,
    processingMessage: undefined,

    affiliations: [],
    affiliationErrors: undefined,

    themes: [],
    equipments: []
};

export default function rideReducer(state = initState, action) {
    switch (action.type) {
        case ActionTypes.RIDE_FETCH_INIT:
        case ActionTypes.RIDE_FETCH_SUCCESS:
        case ActionTypes.RIDE_FETCH_ERROR:
            if (action.errors) {
                state.ride = undefined;
                state.rides = [];
            }

            return fetchReducer(state, action, (state) => {
                state.rides = action.rides;
            });

        case ActionTypes.RIDE_CHANGE_INIT:
        case ActionTypes.RIDE_CHANGE_SUCCESS:
        case ActionTypes.RIDE_CHANGE_ERROR:
            return mutationReducer(state, action, (state) => {
                if (action.processing_job_id) {
                    state.processingJobId = action.processing_job_id;
                    state.ride = action.ride || state.ride;
                } else {
                    state.ride = action.ride;
                }
            });

        case ActionTypes.RIDE_TYPE_CHANGE:
            state.currentRideType = action.newType;
            return state;
        case ActionTypes.RIDE_PREVIOUS_SUCCESS:
            state.currentRideType = action.rideType;
            state.ride = action.localRide;
            return state;
        case ActionTypes.RIDE_JOB_PROGRESS:
            state.processingJobId = action.processingJobId;
            state.processingMessage = action.processingMessage;
            return state;

        case ActionTypes.RIDE_THEME_FETCH_INIT:
        case ActionTypes.RIDE_THEME_FETCH_SUCCESS:
        case ActionTypes.RIDE_THEME_FETCH_ERROR:
            return fetchReducer(state, action, (state) => {
                state.themes = action.themes;
            });
        case ActionTypes.RIDE_THEME_CHANGE_INIT:
        case ActionTypes.RIDE_THEME_CHANGE_SUCCESS:
        case ActionTypes.RIDE_THEME_CHANGE_ERROR:
            return mutationReducer(state, action, (state) => {
                if (action.removedId) {
                    removeIn(state.themes, action.removedId);
                } else {
                    addOrReplaceIn(state.themes, action.theme);
                }
            });

        case ActionTypes.RIDE_EQUIPMENT_FETCH_INIT:
        case ActionTypes.RIDE_EQUIPMENT_FETCH_SUCCESS:
        case ActionTypes.RIDE_EQUIPMENT_FETCH_ERROR:
            return fetchReducer(state, action, (state) => {
                state.equipments = action.equipments;
            });
        case ActionTypes.RIDE_EQUIPMENT_CHANGE_INIT:
        case ActionTypes.RIDE_EQUIPMENT_CHANGE_SUCCESS:
        case ActionTypes.RIDE_EQUIPMENT_CHANGE_ERROR:
            return mutationReducer(state, action, (state) => {
                if (action.removedId) {
                    removeIn(state.equipments, action.removedId);
                } else {
                    addOrReplaceIn(state.equipments, action.equipment);
                }
            });

        default:
            return state;
    }
}
