import * as ActionTypes from '../constants/actionTypes';

import {
    fetchReducer,
    mutationReducer,
    addOrReplaceIn,
    removeIn
} from './mutators';

const initState = {
    // Required for fetchReducer/mutationReducer
    isFetching: false,
    isProcessing: false,
    errors: {},

    products: [],
    pagination: {},

    currentProductType: undefined,
    previousProduct: undefined,
    product: undefined,

    crafts: [],
    materials: [],
    craftsSpecialities: [],
    materialsCrafts: []
};

export default function productReducer(state = initState, action) {
    switch (action.type) {
        case ActionTypes.PRODUCT_FETCH_INIT:
        case ActionTypes.PRODUCT_FETCH_SUCCESS:
        case ActionTypes.PRODUCT_FETCH_ERROR:
            if (action.errors) {
                state.product = undefined;
                state.products = [];
            }

            return fetchReducer(state, action, (state) => {
                state.products = action.products;
            });

        case ActionTypes.PRODUCT_CHANGE_INIT:
        case ActionTypes.PRODUCT_CHANGE_SUCCESS:
        case ActionTypes.PRODUCT_CHANGE_ERROR:
            return mutationReducer(state, action, (state) => {
                state.product = action.product;
                if (action.products) {
                    state.products = action.products;
                }
            });

        case ActionTypes.PRODUCT_PREVIOUS_SUCCESS:
            state.currentProductType = action.productType;
            state.previousProduct = action.previousProduct;
            return state;

        case ActionTypes.PRODUCT_CRAFT_FETCH_INIT:
        case ActionTypes.PRODUCT_CRAFT_FETCH_SUCCESS:
        case ActionTypes.PRODUCT_CRAFT_FETCH_ERROR:
            return fetchReducer(state, action, (state) => {
                state.crafts = action.crafts;
            });
        case ActionTypes.PRODUCT_CRAFT_CHANGE_INIT:
        case ActionTypes.PRODUCT_CRAFT_CHANGE_SUCCESS:
        case ActionTypes.PRODUCT_CRAFT_CHANGE_ERROR:
            return mutationReducer(state, action, (state) => {
                if (action.removedId) {
                    removeIn(state.crafts, action.removedId);
                } else {
                    addOrReplaceIn(state.crafts, action.craft);
                }
            });

        case ActionTypes.PRODUCT_MATERIAL_FETCH_INIT:
        case ActionTypes.PRODUCT_MATERIAL_FETCH_SUCCESS:
        case ActionTypes.PRODUCT_MATERIAL_FETCH_ERROR:
            return fetchReducer(state, action, (state) => {
                state.materials = action.materials;
            });
        case ActionTypes.PRODUCT_MATERIAL_CHANGE_INIT:
        case ActionTypes.PRODUCT_MATERIAL_CHANGE_SUCCESS:
        case ActionTypes.PRODUCT_MATERIAL_CHANGE_ERROR:
            return mutationReducer(state, action, (state) => {
                if (action.removedId) {
                    removeIn(state.materials, action.removedId);
                } else {
                    addOrReplaceIn(state.materials, action.material);
                }
            });

        case ActionTypes.PRODUCT_CRAFTS_SPECIALITY_FETCH_INIT:
        case ActionTypes.PRODUCT_CRAFTS_SPECIALITY_FETCH_SUCCESS:
        case ActionTypes.PRODUCT_CRAFTS_SPECIALITY_FETCH_ERROR:
            return fetchReducer(state, action, (state) => {
                state.craftsSpecialities = action.craftsSpecialities;
            });
        case ActionTypes.PRODUCT_CRAFTS_SPECIALITY_CHANGE_INIT:
        case ActionTypes.PRODUCT_CRAFTS_SPECIALITY_CHANGE_SUCCESS:
        case ActionTypes.PRODUCT_CRAFTS_SPECIALITY_CHANGE_ERROR:
            return mutationReducer(state, action, (state) => {
                if (action.removedId) {
                    removeIn(state.craftsSpecialities, action.removedId);
                } else {
                    addOrReplaceIn(state.craftsSpecialities, action.craftsSpeciality);
                }
            });

        case ActionTypes.PRODUCT_MATERIALS_CRAFT_FETCH_INIT:
        case ActionTypes.PRODUCT_MATERIALS_CRAFT_FETCH_SUCCESS:
        case ActionTypes.PRODUCT_MATERIALS_CRAFT_FETCH_ERROR:
            return fetchReducer(state, action, (state) => {
                state.materialsCrafts = action.materialsCrafts;
            });
        case ActionTypes.PRODUCT_MATERIALS_CRAFT_CHANGE_INIT:
        case ActionTypes.PRODUCT_MATERIALS_CRAFT_CHANGE_SUCCESS:
        case ActionTypes.PRODUCT_MATERIALS_CRAFT_CHANGE_ERROR:
            return mutationReducer(state, action, (state) => {
                if (action.removedId) {
                    removeIn(state.materialsCrafts, action.removedId);
                } else {
                    addOrReplaceIn(state.materialsCrafts, action.materialsCraft);
                }
            });

        default:
            return state;
    }
}
