import * as ActionTypes from '../constants/actionTypes';

import {
    fetchReducer,
    mutationReducer
} from './mutators';

const initState = {
    // Required for fetchReducer/mutationReducer
    isFetching: false,
    isProcessing: false,
    errors: {},
    pagination: {},

    currentBox: undefined,

    inboxConversations: [],
    inboxPagination: {},

    sentboxConversations: [],
    sentboxPagination: {},

    trashConversations: [],
    trashPagination: {},

    currentConversation: undefined,
    currentConversationId: undefined
};

export default function conversationReducer(state = initState, action) {
    switch (action.type) {
        case ActionTypes.CONVERSATION_FETCH_INIT:
        case ActionTypes.CONVERSATION_FETCH_SUCCESS:
        case ActionTypes.CONVERSATION_FETCH_ERROR:
            return fetchReducer(state, action, (state) => {
                if (action.messages) {
                    state.currentBox = action.currentBox;
                    state.currentConversationId = action.conversationId;
                    state.currentConversation = action.messages;
                } else if (action.currentBox === 'inbox') {
                    state.currentBox = action.currentBox;
                    state.inboxConversations = action.conversations;
                    state.inboxPagination = action.meta;
                    state.currentConversationId = undefined;
                } else if (action.currentBox === 'sentbox') {
                    state.currentBox = action.currentBox;
                    state.sentboxConversations = action.conversations;
                    state.sentboxPagination = action.meta;
                    state.currentConversationId = undefined;
                } else if (action.currentBox === 'trash') {
                    state.currentBox = action.currentBox;
                    state.trashConversations = action.conversations;
                    state.trashPagination = action.meta;
                    state.currentConversationId = undefined;
                }
            });

        case ActionTypes.CONVERSATION_CHANGE_INIT:
        case ActionTypes.CONVERSATION_CHANGE_SUCCESS:
        case ActionTypes.CONVERSATION_CHANGE_ERROR:
            return mutationReducer(state, action, (state) => {
                state.conversation = action.conversation;
            });

        default:
            return state;
    }
}
