import * as Utils from '@js/modules/utils';

import {
    getUrlParameter
} from '../modules/utils';

import * as ActionTypes from '../constants/actionTypes';

import {
    addOrReplaceIn,
    fetchReducer,
    removeIn
} from './mutators';

// Autocomplete
const autocompleteState = {
    // Required for fetchReducer/mutationReducer
    isFetching: false,
    pagination: {},
    errors: {},

    query: undefined,

    rides: [],
    shops: [],
    products: [],
    themes: [],
    specialities: [],
    crafts: [],
    materials: []
};

export function autocompleteReducer(state = autocompleteState, action) {
    switch (action.type) {
        case ActionTypes.SEARCH_AUTOCOMPLETE_FETCH_INIT:
        case ActionTypes.SEARCH_AUTOCOMPLETE_FETCH_SUCCESS:
        case ActionTypes.SEARCH_AUTOCOMPLETE_FETCH_ERROR:
            return fetchReducer(state, action, (state) => {
                state.rides = action.rides || [];
                state.shops = action.shops || [];
                state.products = action.products || [];
                state.themes = action.themes || [];
                state.specialities = action.specialities || [];
                state.crafts = action.crafts || [];
                state.materials = action.materials || [];
            });

        default:
            return state;
    }
}

// Search
const searchState = {
    isSearching: false,
    hasResults: false,
    hasSearched: window.seoMode ? false : !!getUrlParameter('query'),

    isArea: false,

    searchParams: {},
    selectedTypes: ['ride', 'shop'], // initial query, // For the moment exclude product
    isOverview: false, // overview activated for initial query
    isMapSearch: false,
    isShowingFilters: false,
    rideFilters: {},
    rideActiveFilters: {},
    shopFilters: {},
    shopActiveFilters: {},
    productFilters: {},
    productActiveFilters: {},

    query: undefined,
    locationPlace: undefined,
    locationCenter: [],
    locationBounds: [],

    rides: [],
    shops: [],
    products: [],

    rideSuggestions: [],
    shopSuggestions: [],
    productSuggestions: [],

    selectedItemType: undefined,
    selectedItemId: undefined,
    cancelSelection: false,
    selectedFromMap: false,

    totalPagesOnMap: undefined,
    totalRidePages: undefined,
    totalShopPages: undefined,
    totalProductPages: undefined,
    totalRides: undefined,
    totalShops: undefined,
    totalProducts: undefined,
    currentPage: 1,
    productCurrentPage: 1,

    nearbyRides: [],
    nearbyShops: [],

    relatedRides: [],
    relatedShops: [],
    relatedProducts: [],

    popularType: undefined,
    popularCommon: undefined,
    popularRides: [],
    popularShops: [],
    popularProducts: [],
    popularPlaces: [],
    popularTouristOffices: [],

    affiliations: [],

    metaResults: {}
};

const _parseSearchResults = (searchState, action) => {
    searchState.isSearching = action.isSearching;
    searchState.isOverview = !!action.isOverview;
    searchState.isMapSearch = !!action.isMapSearch;
    searchState.hasSearched = true;

    searchState.query = action.query;
    searchState.locationPlace = action.locationPlace;
    searchState.locationCenter = action.locationCenter;
    searchState.locationBounds = action.locationBounds;

    searchState.selectedTypes = action.selectedTypes;
    searchState.searchParams = action.searchParams;

    searchState.rides = action.rides || [];
    searchState.shops = action.shops || [];
    searchState.products = action.products || [];

    searchState.hasResults = !(Utils.isEmpty(searchState.rides) && Utils.isEmpty(searchState.shops) && Utils.isEmpty(searchState.products));

    searchState.totalRides = action.totalCount.rides;
    searchState.totalShops = action.totalCount.shops;
    searchState.totalProducts = action.totalCount.products;
    searchState.totalRidePages = action.totalPages.rides;
    searchState.totalShopPages = action.totalPages.shops;
    searchState.totalProductPages = action.totalPages.products;
    searchState.totalPagesOnMap = searchState.totalRidePages || searchState.totalShopPages || 0;

    searchState.currentPage = parseInt(action.page || action.searchParams.page || 1, 10);
    searchState.productCurrentPage = parseInt(action.page || action.searchParams.page || 1, 10);

    if (action.suggestions) {
        searchState.rideSuggestions = action.suggestions.rides || [];
        searchState.shopSuggestions = action.suggestions.shops || [];
        searchState.productSuggestions = action.suggestions.products || [];
    }

    if (action.aggregations) {
        searchState.rideFilters = action.aggregations.rides || {};
        searchState.shopFilters = action.aggregations.shops || {};
        searchState.productFilters = action.aggregations.products || {};
    }

    if (action.rideFilters) {
        searchState.rideActiveFilters = {
            ...searchState.rideActiveFilters,
            ...action.rideFilters
        };
    } else {
        const {
            rideable,
            themes,
            level,
            distance,
            distance_range,
            duration,
            duration_range
        } = action.searchParams;
        searchState.rideActiveFilters = {
            rideable: rideable && !Array.isArray(rideable) ? [rideable] : rideable,
            themes,
            level,
            distance,
            distance_range,
            duration,
            duration_range
        };
    }
    if (action.shopFilters) {
        searchState.shopActiveFilters = {
            ...searchState.shopActiveFilters,
            ...action.shopFilters
        };
    } else {
        const {
            trade,
            specialities
        } = action.searchParams;
        searchState.shopActiveFilters = {
            trade: trade && !Array.isArray(trade) ? [trade] : trade,
            specialities
        };
    }
    if (action.productFilters) {
        searchState.productActiveFilters = {
            ...searchState.productActiveFilters,
            ...action.productFilters
        };
    } else {
        const {
            price,
            priceRange,
            specialities,
            crafts,
            materials
        } = action.searchParams;
        searchState.productActiveFilters = {
            price,
            priceRange,
            specialities,
            crafts,
            materials
        };
    }

    return searchState;
};

const _updateSelectItem = (state, {
    selectedItemType,
    selectedItemId,
    cancelSelection = false,
    selectedFromMap = false
}) => {
    state.selectedItemType = selectedItemType;
    state.selectedItemId = selectedItemId;
    state.cancelSelection = cancelSelection;
    state.selectedFromMap = selectedFromMap;

    return state;
};

export function searchReducer(state = searchState, action) {
    switch (action.type) {
        case ActionTypes.SEARCH_FETCH_INIT:
            state.isSearching = action.isSearching;
            return state;
        case ActionTypes.SEARCH_FETCH_ERROR:
            state.isSearching = false;
            state.hasResults = false;
            return state;
        case ActionTypes.SEARCH_FETCH_SUCCESS:
            return _parseSearchResults(state, action);

        case ActionTypes.SEARCH_FILTERS_TOGGLE:
            state.isShowingFilters = action.hideFilters ? !action.hideFilters : !state.isShowingFilters;
            return state;

        case ActionTypes.SEARCH_AROUND_ME_SUCCESS:
            state.nearbyRides = action.rides;
            state.nearbyShops = action.shops;
            return state;
        case ActionTypes.SEARCH_RELATED_SUCCESS:
            state.relatedRides = action.rides;
            state.relatedShops = action.shops;
            state.relatedProducts = action.products;
            return state;
        case ActionTypes.SEARCH_POPULAR_SUCCESS:
            state.popularType = action.popularType;
            state.popularCommon = action.popularCommon;
            state.popularRides = action.rides || [];
            state.popularShops = action.shops || [];
            state.popularProducts = action.products || [];
            state.popularPlaces = action.places || [];
            // state.popularTouristOffices = action.touristOffices || [];
            return state;

        case ActionTypes.SEARCH_AFFILIATION_SUCCESS:
            state.affiliations = action.affiliations;
            return state;

        case ActionTypes.SEARCH_META_SUCCESS:
            state.isSearching = action.isSearching;
            state.query = action.query;
            state.metaResults = action.metaResults;
            return state;

        // MAP
        case ActionTypes.SEARCH_ITEM_CLICK:
        case ActionTypes.SEARCH_ITEM_OVER:
        case ActionTypes.SEARCH_ITEM_OUT:
            return _updateSelectItem(state, action);

        case ActionTypes.MAP_MARKER_CLICKED:
            return _updateSelectItem(state, {
                selectedItemType: action.markerType,
                selectedItemId: action.markerId,
                selectedFromMap: true
            });
        case ActionTypes.MAP_MARKER_POPUP_CLOSED:
            return _updateSelectItem(state, {
                selectedItemType: null,
                selectedItemId: null,
                cancelSelection: true
            });

        // RIDE
        case ActionTypes.RIDE_CHANGE_SUCCESS:
            if (action.removedId) {
                removeIn(state.rides, action.removedId);
            } else {
                addOrReplaceIn(state.rides, action.ride);
            }
            return state;

        // SHOP
        case ActionTypes.SHOP_CHANGE_SUCCESS:
            if (action.removedId) {
                removeIn(state.shops, action.removedId);
            } else {
                addOrReplaceIn(state.shops, action.shop);
            }
            return state;

        // PRODUCT
        case ActionTypes.PRODUCT_CHANGE_SUCCESS:
            if (action.removedId) {
                removeIn(state.products, action.removedId);
            } else {
                addOrReplaceIn(state.products, action.product);
            }
            return state;

        default:
            return state;
    }
}
