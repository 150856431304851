import * as ActionTypes from '../constants/actionTypes';

import {
    fetchReducer,
    mutationReducer,
    addOrReplaceIn,
    removeIn
} from './mutators';

const initState = {
    // Required for fetchReducer/mutationReducer
    isFetching: false,
    isProcessing: false,
    errors: {},

    isExpanded: false,

    chatRooms: [],
    activeChatRooms: [],
    inactiveChatRooms: [],
    missedChatRooms: [],
    chatRoom: undefined,
    isConnecting: false,

    chatMessages: [],
    pagination: {}
};

export default function chatReducer(state = initState, action) {
    switch (action.type) {
        // Chat Room
        case ActionTypes.CHAT_ROOM_BOX_EXPAND:
            state.isExpanded = action.isExpanded;
            return state;

        case ActionTypes.CHAT_ROOM_FETCH_INIT:
        case ActionTypes.CHAT_ROOM_FETCH_SUCCESS:
        case ActionTypes.CHAT_ROOM_FETCH_ERROR:
            if (action.errors) {
                state.chatRooms = [];
                state.activeChatRooms = [];
                state.inactiveChatRooms = [];
                state.missedChatRooms = [];
                state.chatRoom = undefined;
                state.isConnecting = false;
            }

            return fetchReducer(state, action, (state) => {
                if (action.chatRooms) {
                    if (action.chatRoomParams.active) {
                        state.activeChatRooms = action.chatRooms;
                    } else if (action.chatRoomParams.unread) {
                        state.missedChatRooms = action.chatRooms;
                    }
                } else if (action.chatRoom) {
                    state.chatRoom = action.chatRoom;
                    state.chatMessages = action.chatRoom.messages;
                    state.isConnecting = false;
                }
            });

        // Chat Message
        case ActionTypes.CHAT_ROOM_CHANGE_INIT:
        case ActionTypes.CHAT_ROOM_CHANGE_SUCCESS:
        case ActionTypes.CHAT_ROOM_CHANGE_ERROR:
            return mutationReducer(state, action, (state) => {
                if (action.removedId) {
                    state.activeChatRooms = removeIn(state.activeChatRooms, action.removedId);
                    state.missedChatRooms = removeIn(state.missedChatRooms, action.removedId);
                } else {
                    state.chatRoom = action.chatRoom;
                    state.chatMessages = action.chatRoom.messages;
                }
            });

        case ActionTypes.CHAT_ROOM_BOX_INACTIVE:
            state.activeChatRooms = removeIn(state.activeChatRooms, action.inactiveChatRoom.id);
            state.inactiveChatRooms.push(action.inactiveChatRoom);
            return state;

        case ActionTypes.CHAT_MESSAGE_FETCH_INIT:
        case ActionTypes.CHAT_MESSAGE_FETCH_SUCCESS:
        case ActionTypes.CHAT_MESSAGE_FETCH_ERROR:
            return fetchReducer(state, action, (state) => {
                state.chatMessages = action.chatMessages;
            });

        case ActionTypes.CHAT_MESSAGE_CHANGE_INIT:
        case ActionTypes.CHAT_MESSAGE_CHANGE_SUCCESS:
        case ActionTypes.CHAT_MESSAGE_CHANGE_ERROR:
            return mutationReducer(state, action, (state) => {
                state.chatMessages = addOrReplaceIn(state.chatMessages, action.chatMessage);
            });

        case ActionTypes.CHAT_MESSAGE_NO_MESSAGE_SUCCESS:
            state.isProcessing = action.isProcessing;
            state.chatMessage = action.chatMessage;
            return state;

        default:
            return state;
    }
}
