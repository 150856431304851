import * as ActionTypes from '../constants/actionTypes';

import {
    fetchReducer,
    mutationReducer,
    addOrReplaceIn,
    removeIn
} from './mutators';

const initState = {
    // Required for fetchReducer/mutationReducer
    isFetching: false,
    isProcessing: false,
    errors: {},

    touristOffices: [],
    pagination: {}
};

export default function touristOfficeReducer(state = initState, action) {
    switch (action.type) {
        case ActionTypes.TOURIST_OFFICE_FETCH_INIT:
        case ActionTypes.TOURIST_OFFICE_FETCH_SUCCESS:
        case ActionTypes.TOURIST_OFFICE_FETCH_ERROR:
            if (action.errors) {
                state.touristOffices = [];
            }

            return fetchReducer(state, action, (state) => {
                state.touristOffices = action.touristOffices;
            });

        case ActionTypes.TOURIST_OFFICE_CHANGE_INIT:
        case ActionTypes.TOURIST_OFFICE_CHANGE_SUCCESS:
        case ActionTypes.TOURIST_OFFICE_CHANGE_ERROR:
            return mutationReducer(state, action, (state) => {
                if (action.removedId) {
                    removeIn(state.touristOffices, action.removedId);
                } else {
                    addOrReplaceIn(state.touristOffices, action.touristOffice);
                }
            });

        default:
            return state;
    }
}
